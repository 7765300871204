<template>
  <div>
    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-users"></i> Agentes Cadastrados
        </h5>
      </div>

      <div class="w-100 p-3">
        <b-button variant="primary" :to="{ name: 'agents-create' }">
          <i class="fas fa-plus"></i> Adicionar novo agente
        </b-button>
      </div>

      <div class="w-100 p-3">
        <b-table
        bordered
        responsive
        :busy="load"
        :items="agents"
        :fields="columns">
        <template #table-busy>
          <div class="text-center text-success my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Carregando...</strong>
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button
          v-b-tooltip.hover
          title="Editar cliente"
          :to="{ name: 'agents-edit', params: { id: data.item.id } }"
          size="sm"
          class="mr-1"
          variant="warning">
            <i class="fas fa-pen"></i>
          </b-button>
        </template>
        </b-table>
      </div>
    </b-row>
  </div>
</template>
<script>
import AgentService from '@/Services/AgentService';

export default {
  data() {
    return {
      columns: AgentService.getFieldsTableAgents(),
      load: true,
      agents: [],
    };
  },
  methods: {
    async getList() {
      const { data } = await AgentService.getList();
      this.agents = data.map((item) => ({
        id: item.id,
        name: item.name,
        email: item.email,
        contact: item.phone_mobile,
        city: item.address.city.name,
        province: item.address.city.province.initial,
      }));

      this.load = false;
    },
  },
  created() {
    this.getList();
  },
};
</script>
